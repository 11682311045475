import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import CvFr from "../img/Sami_Begah_CV_FR.jpg";
import CvEn from "../img/Sami_Begah_CV_EN.jpg";

function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(message, email, name);
    if (message !== "" && email !== "" && name !== "") {
      emailjs
        .sendForm(
          "service_xrehgea",
          "template_n3qhpiv",
          form.current,
          "qBQpGVaZakC_8-FKe"
        )
        .then((result) => {
          if (result.text === "OK") {
            e.target.reset();
            setSendStatus("Message sent!");
          } else {
            setSendStatus("Oops, something went wrong. Try again?");
          }
        });
    }
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sendStatus, setSendStatus] = useState("Send");

  return (
    <div
      className="w-full h-full flex flex-col transition-all duration-200  bg-slate-950 p-10 lg:p-20 relative text-slate-200"
      id="contact"
    >
      <div className="max-w-screen-2xl  mx-auto w-full ">
        <div className="  justify-center items-center gap-2 flex absolute bg-white border  rounded-xl shadow-2xl p-2 w-1/2 lg:w-1/3 px-10 py-5 -top-11 mx-auto text-center  right-0 left-0">
          <h2 className=" text-2xl lg:text-4xl h-full flex justify-center font-bold text-[#0F172A] tracking-widest ">
            CONTACT
          </h2>
        </div>
        <div className=" w-full h-full  grid grid-cols-4 lg:grid-cols-4 items-center py-20 gap-10 lg:gap-20 text-base lg:text-lg">
          <div className=" flex flex-col items-center justify-center h-full order-3 col-span-2 lg:col-span-1  lg:order-none">
            <div className=" rounded-3xl w-full items-center justify-center flex flex-col ">
              <div className="flex flex-col items-center h-full d3- gap-5 justify-center   relative group">
                <span className="text-lg lg:text-xl whitespace-nowrap">
                  Curriculum Vitae (EN)
                </span>
                <div className="w-full h-full flex flex-col items-center gap-2 relative">
                  <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 group-hover:opacity-40 transition-all duration-1000 rounded-xl"></div>
                  <img
                    src={CvEn}
                    alt="CV Sami Begah"
                    className=" transition-all duration-1000  ease-in-out  rounded-xl w-96  shadow-2xl"
                  />

                  <a
                    href="https://drive.google.com/u/0/uc?id=1cCrRdKsPslpyzyyqTZ6t0tFnK9klyspW&export=download"
                    className="absolute top-0 left-0 w-full h-full flex "
                  >
                    <button className="relative items-center justify-center w-full h-full inline-block px-5 py-3 overflow-hidden font-bold rounded-xl opacity-0 text-white group-hover:opacity-100 transition-all duration-1000">
                      Download
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center  rounded-r-2xl col-span-4 lg:col-span-2 ">
            <div className=" rounded-3xl flex flex-col justify-between w-full h-full items-center gap-10 ">
              <div className=" text-center  text-2xl lg:text-3xl leading-10 rounded-xl flex flex-col z-10 gap-5">
                <span className="  rounded-t-2xl">
                  Interested by my work? <br />
                </span>
                <p className=" ">Let's connect.</p>
              </div>
              <div className="   w-full h-full rounded-xl flex justify-center   transition-all ease-in-out duration-1000 ">
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className=" w-full  flex flex-col  gap-10 z-10"
                >
                  <div className="flex flex-col  text-lg gap-1 ">
                    <input
                      type="text"
                      name="from_name"
                      placeholder="Your name."
                      className="rounded-lg text-slate-200 text-lg  bg-blue-500 bg-opacity-10 border-none shadow"
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col  text-lg gap-1 ">
                    <input
                      type="email"
                      name="from_email"
                      placeholder="Your email."
                      className="rounded-lg text-slate-200 text-lg  bg-blue-500 bg-opacity-10 border-none shadow"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col  text-lg gap-1">
                    <textarea
                      name="message"
                      placeholder="Your message..."
                      className="h-40 rounded-lg text-slate-200 text-lg  bg-blue-500 bg-opacity-10 border-none shadow"
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <button
                    type="submit"
                    value="Send"
                    className="bg-[#1F25AF] text-white w-full rounded-lg p-2 px-5 shadow-l border-[#1F25AF] border hover:bg-white hover:text-slate-900 transition-all duration-200"
                  >
                    {sendStatus}
                  </button>
                </form>
              </div>
            </div>
          </div>

          <div className=" flex flex-col items-center justify-center h-full order-3 col-span-2 lg:col-span-1  lg:order-none">
            <div className=" rounded-3xl w-full items-center justify-center flex flex-col ">
              <div className="flex flex-col items-center h-full d3 gap-5 justify-center   relative group">
                <span className="text-lg lg:text-xl whitespace-nowrap">
                  Curriculum Vitae (FR)
                </span>
                <div className="w-full h-full flex flex-col items-center gap-2 relative">
                  <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 group-hover:opacity-40 transition-all duration-1000 rounded-xl"></div>
                  <img
                    src={CvFr}
                    alt="CV Sami Begah"
                    className=" transition-all duration-1000  ease-in-out  rounded-xl w-96  shadow-2xl"
                  />

                  <a
                    href="https://drive.google.com/u/0/uc?id=156ah_jAWw9qP7UfhD6QG-taHqYE_1M4F&export=download"
                    className="absolute top-0 left-0 w-full h-full flex "
                  >
                    <button className="relative items-center justify-center w-full h-full inline-block px-5 py-3 overflow-hidden font-bold rounded-xl opacity-0 text-white group-hover:opacity-100 transition-all duration-1000">
                      Télécharger
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
