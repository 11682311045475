import Linkedin from "../img/linkedin.png";
import Github from "../img/github.png";
import Email from "../img/email.png";
import Sb from "../img/sb-nav.png";

function NavBar() {
  return (
    <nav className="  flex w-full items-center justify-between  shadow-xl bg-[#F9F9F9] bg-opacity-95 py-3 lg:py-5 px-5 lg:px-10 fixed z-50">
      <div className="flex w-1/4">
        <img src={Sb} alt="logo" className="w-6 lg:w-8 scale-125 " />
      </div>
      <div className="h-full gap-2 lg:gap-5 flex justify-end  text-slate-950  lg:text-xl transition-all duration-1000 ease-in-out ">
        <a
          href="#about"
          className="text-center  cursor-pointer transition-all duration-200 ease-in-out hover:scale-105 transitional-all flex items-center"
        >
          About
        </a>

        <a
          href="#projects"
          className="text-center  cursor-pointer transition-all duration-200 ease-in-out hover:scale-105 transitional-all flex items-center"
        >
          {" "}
          Projects
        </a>
        <a
          href="#contact"
          className="text-center  cursor-pointer transition-all duration-200 ease-in-out hover:scale-105 transitional-all flex items-center"
        >
          Contact
        </a>
      </div>
      <div className="flex items-center justify-end w-1/4 gap-1 lg:gap-2">
        <a
          href="https://github.com/SamiBegah"
          className="flex gap-1 cursor-pointer  items-center   group"
        >
          <img
            src={Github}
            alt="Link to Github"
            className="w-6 lg:w-8 group-hover:scale-110 transition-all duration-200 ease-in-out hover:scale-105 "
          />
        </a>
        <a
          href="https://www.linkedin.com/in/sami-begah-08606a280/"
          className="flex gap-1 cursor-pointer  items-center  group"
        >
          <img
            src={Linkedin}
            alt="Link to Linkedin"
            className="w-6 lg:w-8 group-hover:scale-110 transition-all duration-200 ease-in-out hover:scale-105 "
          />
        </a>
        <a
          href="mailto:begah.sami@outlook.com"
          className="flex gap-1 cursor-pointer  items-center  group"
        >
          <img
            src={Email}
            alt="Link to Github"
            className="w-6 lg:w-8 group-hover:scale-110 transition-all duration-200 ease-in-out hover:scale-105 "
          />
        </a>
      </div>
    </nav>
  );
}

export default NavBar;
