import LalalandLogo from "../img/lalaland/lalaland-logo.png";
import Lalaland1 from "../img/lalaland/lalaland-1.png";
import Lalaland2 from "../img/lalaland/lalaland-2.png";
import Lalaland3 from "../img/lalaland/lalaland-3.png";
import Lalaland4 from "../img/lalaland/lalaland-4.png";
import TaskproLogo from "../img/taskpro/taskpro-logo.png";
import Taskpro1 from "../img/taskpro/taskpro-1.png";
import Taskpro2 from "../img/taskpro/taskpro-2.png";
import Taskpro3 from "../img/taskpro/taskpro-3.png";
import Taskpro4 from "../img/taskpro/taskpro-4.png";
import GpuLogo from "../img/gpu/gpu-logo.png";
import Gpu1 from "../img/gpu/gpu-1.png";
import Gpu2 from "../img/gpu/gpu-2.png";
import Gpu3 from "../img/gpu/gpu-3.png";
import Gpu4 from "../img/gpu/gpu-4.png";
import PlanbLogo from "../img/planb/planb-logo.png";
import Planb1 from "../img/planb/planb-1.png";
import Planb2 from "../img/planb/planb-2.png";
import Planb3 from "../img/planb/planb-3.png";
import Planb4 from "../img/planb/planb-4.png";
import TutoractionLogo from "../img/tutoraction/tutoraction-logo.png";
import Tutoraction1 from "../img/tutoraction/tutoraction-1.png";
import Tutoraction2 from "../img/tutoraction/tutoraction-2.png";
import Tutoraction3 from "../img/tutoraction/tutoraction-3.png";
import Tutoraction4 from "../img/tutoraction/tutoraction-4.png";
import Github from "../img/github.png";
import { Carousel } from "flowbite-react";

function Projects() {
  return (
    <div
      className=" w-full h-full flex flex-col bg-[#1F25AF] p-10 lg:p-20 relative text-slate-200"
      id="projects"
    >
      <div className="  w-full h-full flex flex-col justify-center items-center max-w-screen-2xl mx-auto text-base lg:text-lg">
        <div className="  justify-center items-center gap-2 flex absolute bg-white border  rounded-xl shadow-2xl p-2 w-1/2 lg:w-1/3 px-10 py-5 -top-11 mx-auto text-center  right-0 left-0">
          <h2 className=" text-2xl lg:text-4xl h-full flex justify-center font-bold text-[#0F172A] tracking-widest ">
            PROJECTS
          </h2>
        </div>
        <div className=" grid grid-cols-1 lg:grid-cols-2 grid-flow-row items-center gap-10 py-20 w-full h-full  ">
          <div className=" text-center text-white bg-black border-t border-gray-600 rounded-lg bg-opacity-25 shadow-xl z-10 py-5">
            <div className=" flex flex-col  items-center h-full w-full  rounded-b-xl">
              <div className=" flex justify-center items-center text-xl lg:text-2xl rounded-t-xl w-full h-12 lg:h-24">
                <img
                  src={PlanbLogo}
                  alt="Gpu Canada logo"
                  className="max-h-8 lg:max-h-12 m-2"
                />
                <a
                  href="https://planbplacement.ca/"
                  className="flex py-2 rounded-xl"
                  target="blank"
                >
                  <h2 className="font-bold  px-2 ">Plan B Placement</h2>
                  <sup className="text-sm ">(→)</sup>
                </a>
              </div>

              <Carousel
                className=" w-full px-5 pb-10 lg:px-10 h-[300px] md:h-[400px]"
                leftControl
                rightControl
              >
                <img
                  alt="..."
                  src={Planb1}
                  className="object-contain overflow-hidden rounded-lg opacity-95"
                />
                <img
                  alt="..."
                  src={Planb2}
                  className="object-contain overflow-hidden rounded-lg opacity-95"
                />
                <img
                  alt="..."
                  src={Planb3}
                  className="object-contain overflow-hidden rounded-lg opacity-95"
                />
                <img
                  alt="..."
                  src={Planb4}
                  className="object-contain overflow-hidden rounded-lg opacity-95"
                />
              </Carousel>

              <div className="flex flex-col gap-5  text-justify px-5">
                <p className="px-5">
                  A comprehensive employment agency website providing user
                  login, job applications, search functionality, and an
                  administrative portal for advanced company management tools.
                </p>
                <ul className="w-full p-2  text-center flex justify-center flex-wrap gap-2 lg:gap-5  whitespace-nowrap">
                  <li className="rounded-xl  bg-[#E44F26] bg-opacity-90 shadow-xl p-2 flex h-fit justify-center items-center">
                    HTML5
                  </li>
                  <li className="rounded-xl  bg-[#05B0CE]  bg-opacity-90 shadow-xl p-2 whitespace-nowrap py-2 flex justify-center items-center">
                    Tailwind CSS
                  </li>
                  <li className="rounded-xl  bg-[#5DD3F3]  bg-opacity-90 shadow-xl p-2 flex justify-center items-center">
                    React.js
                  </li>
                  <li className="rounded-xl  bg-[#FFA626]  bg-opacity-90 shadow-xl p-2 flex justify-center items-center">
                    Firebase
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className=" text-center h-full flex flex-col justify-start items-center text-white bg-black border-t border-gray-600 rounded-lg bg-opacity-25 shadow-xl z-10 py-5">
            <div className="flex flex-col  w-full  ">
              <div className="flex-1  flex flex-col justify-between  h-full items-center  rounded-b-xl">
                <div className=" flex justify-center items-center text-xl lg:text-2xl rounded-t-xl w-full h-16 lg:h-24">
                  <img
                    src={LalalandLogo}
                    alt="Lalaland logo"
                    className="max-h-10 lg:max-h-14 m-2"
                  />
                  <a
                    href="http://lalalandyoga.com/"
                    className="flex py-2 rounded-xl"
                    target="blank"
                  >
                    <h2 className="font-bold  px-2 ">Lalaland Yoga</h2>
                    <sup className="text-sm ">(→)</sup>
                  </a>
                </div>

                <Carousel
                  className=" w-full px-5 pb-10 lg:px-10 h-[300px] md:h-[400px]"
                  leftControl
                  rightControl
                >
                  <img
                    alt="..."
                    src={Lalaland1}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Lalaland2}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Lalaland3}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Lalaland4}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                </Carousel>
                <div className="flex flex-col gap-5  text-justify px-5">
                  <p className="px-5">
                    Professional yoga retreats web site. Display information for
                    retreats and uses an automatic email API system.
                  </p>
                  <ul className="w-full p-2  text-center flex justify-center flex-wrap gap-2 lg:gap-5  whitespace-nowrap">
                    <li className="rounded-xl  bg-[#E44F26] bg-opacity-90 shadow-xl p-2 flex h-fit justify-center items-center">
                      HTML5
                    </li>
                    <li className="rounded-xl  bg-[#05B0CE] bg-opacity-90 shadow-xl p-2 flex h-fit justify-center items-center">
                      Tailwind CSS
                    </li>
                    <li className="rounded-xl  bg-[#EFD82E] bg-opacity-90 shadow-xl p-2 flex  h-fit justify-center items-center">
                      Javascript
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className=" text-center h-full flex flex-col justify-start items-center  bg-black border-t border-gray-600 rounded-lg bg-opacity-25 shadow-xl z-10 py-5">
            <div className="flex flex-col  w-full  ">
              <div className="flex-1  flex flex-col justify-center items-center text-white rounded-b-xl ">
                <div className=" flex justify-center items-center text-xl lg:text-2xl rounded-t-xl w-full h-16 lg:h-24">
                  <img
                    src={TaskproLogo}
                    alt="TaskPro logo"
                    className="max-h-10 lg:max-h-14 m-2"
                  />
                  <a
                    href="https://taskpro-2ac88.web.app/"
                    className="flex py-2 rounded-xl"
                    target="blank"
                  >
                    <h2 className="font-bold  px-2 ">Task Pro</h2>
                    <sup className="text-sm  ">(→)</sup>
                  </a>
                </div>

                <Carousel
                  className=" w-full px-5 pb-10 lg:px-10 h-[300px] md:h-[400px]"
                  leftControl
                  rightControl
                >
                  <img
                    alt="..."
                    src={Taskpro1}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Taskpro2}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Taskpro3}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Taskpro4}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                </Carousel>
                <div className="flex flex-col gap-5  text-justify px-5">
                  <p className="px-5">
                    Task manager application. User can register and login to
                    create, manage and accomplish tasks. Incorporates a timer
                    system to manage productive sessions. User can also view
                    tasks in statistics and calendar page. A weather API is used
                    to display local user weather.
                  </p>
                  <ul className="w-full p-2  text-center flex justify-center flex-wrap gap-2 lg:gap-5  whitespace-nowrap">
                    <li className="rounded-xl  bg-[#E44F26] bg-opacity-90 shadow-xl p-2 flex h-fit justify-center items-center">
                      HTML5
                    </li>
                    <li className="rounded-xl  bg-[#05B0CE]  bg-opacity-90 shadow-xl p-2 whitespace-nowrap py-2 flex justify-center items-center">
                      Tailwind CSS
                    </li>
                    <li className="rounded-xl  bg-[#5DD3F3]  bg-opacity-90 shadow-xl p-2 flex justify-center items-center">
                      React.js
                    </li>
                    <li className="rounded-xl  bg-[#FFA626]  bg-opacity-90 shadow-xl p-2 flex justify-center items-center">
                      Firebase
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className=" text-center h-full flex flex-col justify-start items-center text-white bg-black border-t border-gray-600 rounded-lg bg-opacity-25 shadow-xl z-10 py-5">
            <div className="flex flex-col  w-full ">
              <div className="flex-1  flex flex-col justify-center items-center  rounded-b-xl">
                <div className=" flex justify-center items-center text-xl lg:text-2xl rounded-t-xl w-full h-16 lg:h-24">
                  <img
                    src={TutoractionLogo}
                    alt="Gpu Canada logo"
                    className="max-h-8 lg:max-h-12 m-2"
                  />
                  <a
                    href="https://www.tutoraction.ca/"
                    className="flex py-2 rounded-xl"
                    target="blank"
                  >
                    <h2 className="font-bold  px-2 ">Tutoraction</h2>
                    <sup className="text-sm ">(→)</sup>
                  </a>
                </div>

                <Carousel
                  className=" w-full px-5 pb-10 lg:px-10 h-[300px] md:h-[400px]"
                  leftControl
                  rightControl
                >
                  <img
                    alt="..."
                    src={Tutoraction1}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Tutoraction2}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Tutoraction3}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Tutoraction4}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                </Carousel>

                <div className="flex flex-col gap-5  text-justify px-5">
                  <p className="px-5">
                    A tutoring website designed to help students match with the
                    best tutors. Responsible for engineering and managing the
                    site, including updates, content, and SEO strategies,
                    attracting approximately 10,000 unique visitors.
                  </p>
                  <ul className="w-full p-2  text-center flex justify-center flex-wrap gap-2 lg:gap-5  whitespace-nowrap">
                    <li className="rounded-xl  bg-[#454140]  shadow-md p-2 flex justify-center items-center">
                      Wordpress
                    </li>
                    <li className="rounded-xl  bg-[#E44F26]  shadow-md p-2 flex justify-center items-center">
                      HTML5
                    </li>
                    <li className="rounded-xl  bg-[#2762E9]  shadow-md p-2 flex justify-center items-center">
                      CSS3
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className=" text-center h-full flex flex-col justify-start items-center text-white bg-black border-t border-gray-600 rounded-lg bg-opacity-25 shadow-xl z-10 py-5">
            <div className="flex flex-col  w-full ">
              <div className="flex-1  flex flex-col justify-center items-center  rounded-b-xl">
                <div className=" flex justify-center items-center text-xl lg:text-2xl rounded-t-xl w-full h-16 lg:h-24">
                  <img
                    src={GpuLogo}
                    alt="Gpu Canada logo"
                    className="max-h-8 lg:max-h-12 m-2"
                  />
                  <h2 className="font-bold  m-2 py-6 px-2">GPU Canada</h2>
                </div>

                <Carousel
                  className=" w-full px-5 pb-10 lg:px-10 h-[300px] md:h-[400px]"
                  leftControl
                  rightControl
                >
                  <img
                    alt="..."
                    src={Gpu1}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Gpu2}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Gpu3}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                  <img
                    alt="..."
                    src={Gpu4}
                    className="object-contain overflow-hidden rounded-lg opacity-95"
                  />
                </Carousel>

                <div className="flex flex-col gap-5  text-justify px-5">
                  <p className="px-5">
                    Prototype of a graphic cards online store. Users can
                    register and login to purchase desired items. Admin can
                    manage stocks and deactivate a member's account.
                    Technologies used:
                  </p>
                  <ul className="w-full p-2  text-center flex justify-center flex-wrap gap-2 lg:gap-5  whitespace-nowrap">
                    <li className="rounded-xl  bg-[#E44F26]  shadow-md p-2 flex justify-center items-center">
                      HTML5
                    </li>
                    <li className="rounded-xl  bg-[#2762E9]  shadow-md p-2 flex justify-center items-center">
                      CSS3
                    </li>
                    <li className="rounded-xl  bg-[#2B72BF]  shadow-md p-2 flex justify-center items-center">
                      TypeScript
                    </li>
                    <li className="rounded-xl  bg-[#3E6E93]  shadow-md p-2 flex justify-center items-center">
                      MySQL
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className=" text-center h-full flex flex-col justify-center items-center text-white lg:bg-black lg:bg-opacity-25 lg:border-t border-gray-600 rounded-lg bg-opacity-25 lg:shadow-xl z-10 py-5">
            <a
              href="https://github.com/SamiBegah"
              className="flex group gap-2 h-fit justify-center items-center text-self-center bg-white bg-opacity-5 w-fit mx-auto p-2 px-5 rounded-xl border-t border-gray-600 text-white text-xl lg:text-xl cursor-pointer hover:scale-105 transition-all duration-500 ease-in-out"
            >
              <img
                src={Github}
                alt="github logo"
                className="w-12 transition-all duration-500 ease-in-out"
              />
              More on GitHub →
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
