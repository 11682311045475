import { useState, useEffect } from "react";
import Job from "../img/job.png";
import Frontend from "../img/frontend.gif";
import Backend from "../img/backend.gif";
import Coding from "../img/coding.gif";
import Responsive from "../img/responsive.gif";
import Ux from "../img/ux.gif";
import Problem from "../img/problem.gif";
import Js from "../img/skills/js.png";
import Reactjs from "../img/skills/react.png";
import Nodejs from "../img/skills/nodejs.png";
import Ts from "../img/skills/ts.png";
import Tailwindcss from "../img/skills/tailwindcss.png";
import Html5 from "../img/skills/html5.png";
import Css3 from "../img/skills/css3.png";
import Wordpress from "../img/skills/wordpress.png";
import { Player } from "@lottiefiles/react-lottie-player";
import Sb from "../img/sb.png";

function About() {
  const [skillsBar, setSkillsBar] = useState([
    {
      name: "HTML5",
      pourcentage: 95,
      color: "#E44F26",
      type: "main",
      logo: Html5,
    },
    {
      name: "CSS3",
      pourcentage: 95,
      color: "#2762E9",
      type: "main",
      logo: Css3,
    },
    {
      name: "Javascript",
      pourcentage: 90,
      color: "#EFD82E",
      type: "main",
      logo: Js,
    },
    {
      name: "React.js",
      pourcentage: 85,
      color: "#5DD3F3",
      type: "main",
      logo: Reactjs,
    },
    {
      name: "TypeScript",
      pourcentage: 85,
      color: "#2B72BF",
      type: "main",
      logo: Ts,
    },
    {
      name: "Tailwind CSS",
      pourcentage: 90,
      color: "#05B0CE",
      type: "main",
      logo: Tailwindcss,
    },
    {
      name: "Node.js",
      pourcentage: 90,
      color: "#609555",
      type: "main",
      logo: Nodejs,
    },

    {
      name: "Wordpress",
      pourcentage: 90,
      color: "#454140",
      type: "main",
      logo: Wordpress,
    },

    {
      name: "MySQL",
      color: "#3E6E93",
      type: "secondary",
    },
    {
      name: "MangoDb",
      color: "#52A74A",
      type: "secondary",
    },
    {
      name: "FireBase",
      color: "#FFA626",
      type: "secondary",
    },
    {
      name: "C#",
      color: "#1E9924",
      type: "secondary",
    },
    {
      name: "Java",
      color: "#5482A1",
      type: "secondary",
    },

    {
      name: "ASP .NET",
      color: "#9911BD",
      type: "secondary",
    },
    {
      name: "PHP",
      color: "#4C588E",
      type: "secondary",
    },
    {
      name: "BootStrap",
      color: "#8513F3",
      type: "secondary",
    },
    {
      name: "SEO",
      color: "#20B1D3",
      type: "secondary",
    },
    {
      name: "Spring",
      color: "#6CB52D",
      type: "secondary",
    },
    {
      name: "SQL",
      color: "#0F152E",
      type: "secondary",
    },
    {
      name: "Angular",
      color: "#DD0031",
      type: "secondary",
    },
  ]);

  return (
    <div className=" w-full flex flex-col ">
      <div className=" w-full h-full  flex flex-col justify-center">
        <div className=" flex flex-col items-between h-full lg:h-full  ">
          {/* About me */}
          <div className="flex flex-col h-full w-full  relative ">
            <div className="h-full flex flex-col items-center justify-center w-full">
              <div className="flex-1 flex py-32 lg:py-60 gap-10 flex-col lg:flex-row  w-full justify-center items-center bg-slate-900 p-5 relative overflow-hidden">
                <Player
                  src="https://lottie.host/d3dd1969-96ab-4550-92fd-6047ea5db2b0/1al3ES6gXq.json"
                  className="player right-0 left-0 top-0 bottom-0 absolute opacity-10 scale-150 h-full "
                  loop
                  autoplay
                />
                <img src={Sb} alt="logo" className="" />
                <div className=" w-fit  text-center text-xl lg:text-3xl  flex flex-col gap-5 lg:text-justify text-blue-300 z-30">
                  <span className="text-4xl lg:text-6xl font-bold tracking-widest text-white">
                    HELLO WORLD.
                  </span>
                  <p className="">
                    My name is{" "}
                    <span className=" font-bold text-white ">Sami Begah</span>.{" "}
                  </p>
                  <p>
                    I am a{" "}
                    <span className="font-bold text-white ">
                      web developer{" "}
                    </span>
                    from{" "}
                    <span className=" font-bold text-white ">Montreal</span>,{" "}
                    <span className=" font-bold text-white ">Canada</span>.
                  </p>
                </div>
              </div>

              <div
                className="p-10 lg:p-20  relative max-w-screen-2xl  "
                id="about"
              >
                <div className="  justify-center items-center gap-2 flex absolute bg-white border  rounded-xl shadow-2xl p-2 w-1/2 lg:w-1/3 px-10 py-5 -top-11 mx-auto text-center  right-0 left-0">
                  <h2 className=" text-2xl lg:text-4xl h-full flex justify-center font-bold text-[#0F172A] tracking-widest ">
                    ABOUT
                  </h2>
                </div>
                <div className="flex flex-col lg:flex-row py-20 w-full justify-between gap-8 lg:gap-16  text-base lg:text-lg  ">
                  <div className=" text-start flex flex-col lg:flex-col  gap-8 lg:gap-16 w-full lg:w-5/12 justify-between  text-slate-200  ">
                    <div className="flex gap-5 ">
                      <span className="flex text-[#1F25AF] text-lg lg:text-xl font-bold items-center justify-center text-vertical tracking-widest py-2 p-1 bg-white border-t border-gray-600 rounded-lg  shadow-xl ">
                        2021
                      </span>
                      <div className="flex flex-col gap-2">
                        <p className="p-5 bg-black border-t border-gray-600 bg-opacity-25 rounded-xl shadow w-full ">
                          Embarked on my computer science journey, mastering
                          coding basics and computer theory, laying the
                          foundations for programming.
                        </p>
                        <div className=" w-full flex flex-col items-center justify-between gap-2">
                          <div className="flex gap-2 bg-white bg-opacity-5 p-2 border-t border-gray-600 rounded-xl shadow h-full w-full justify-center items-center">
                            <img src={Job} className="w-5 lg:w-6" alt="job" />
                            <p className="">
                              Webmaster - <b>TutorAction</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-5 ">
                      <span className="flex text-[#1F25AF] font-bold text-lg lg:text-xl items-center justify-center text-vertical tracking-widest py-2 p-1 bg-white border-t border-gray-600 rounded-lg  shadow-xl ">
                        2022
                      </span>{" "}
                      <div className="flex flex-col gap-2">
                        <p className="p-5 bg-black border-t border-gray-600 bg-opacity-25 rounded-xl shadow w-full  ">
                          Immersed myself in web development, completing a{" "}
                          <a
                            className=" font-bold  hover:scale-105 transition-all ease-in-out duration-500"
                            href="https://www.collegeahuntsic.qc.ca/formation-continue/aec-intensives/developpement-de-sites-web-transactionnels"
                          >
                            1-year intensive program
                          </a>{" "}
                          to gain hands-on experience through practical
                          projects.
                        </p>
                        <div className=" w-full flex flex-col items-center justify-between gap-2">
                          <div className="flex gap-2 bg-white bg-opacity-5 p-2 border-t border-gray-600 rounded-xl shadow  h-full w-full justify-center items-center">
                            <img src={Job} className="w-5 lg:w-6" alt="job" />
                            <p>
                              Webmaster - <b>TutorAction</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex gap-5 ">
                      <span className="flex text-[#1F25AF] text-lg lg:text-xl font-bold items-center justify-center text-vertical tracking-widest py-2 p-1 bg-white border-t border-gray-600 rounded-lg  shadow-xl ">
                        2023
                      </span>{" "}
                      <div className="flex flex-col gap-2">
                        <p className="p-5 bg-black border-t border-gray-600 bg-opacity-25 rounded-xl shadow w-full  ">
                          Graduated as a proficient web developer, showcasing
                          skills through modern and user-centric digital
                          experiences.
                        </p>
                        <div className=" w-full flex flex-col items-center justify-between gap-2">
                          <div className="flex gap-2 bg-white bg-opacity-5 p-2 border-t border-gray-600 rounded-xl shadow  h-full w-full justify-center items-center">
                            <img src={Job} className="w-5 lg:w-6" alt="job" />
                            <p>
                              Geek Squad Agent - <b>BestBuy</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col h-full gap-8 lg:gap-16 lg:justify-between w-full lg:w-6/12">
                    <div className="w-full flex flex-col  mx-auto text-white  justify-center gap-2 ">
                      <span className="flex font-bold text-lg lg:text-xl text-[#1F25AF] items-center justify-center tracking-widest py-2 p-1 bg-white border-t border-gray-600 rounded-lg  shadow ">
                        TODAY
                      </span>
                      <div className="flex flex-col gap-2  items-center">
                        <div className="flex flex-col  gap-2 bg-black bg-opacity-25 rounded-xl shadow-xl text-white border-t border-gray-600 flex-1 w-full p-5">
                          <p className=" h-full  ">
                            Currenctly managing the{" "}
                            <i>Association des Scouts du Canada</i>'s website,
                            ensuring regular maintenance of the site, data
                            entry, content migrations, and various other
                            web-related tasks.
                          </p>
                          <p>
                            While fulfilling this role, I continue to expand my
                            web development expertise by taking on freelance
                            projects on the side, further developing my skills
                            and staying current with the latest technologies.
                          </p>
                        </div>

                        <div className="w-full flex flex-col justify-around gap-2">
                          <div className="flex gap-2 bg-white bg-opacity-5 p-2 border-t border-gray-600 rounded-xl shadow  text-center h-full w-full justify-center items-center">
                            <img src={Job} className="w-5 lg:w-6" alt="job" />
                            <p>
                              Developer/Webmaster - <b>Scouts du Canada</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="  text-white   flex flex-col gap-2 justify-center items-center   ">
                      <span className="flex font-bold text-lg lg:text-xl  text-[#1F25AF] items-center justify-center  tracking-widest py-2 p-1 bg-white border-t border-gray-600 rounded-lg  shadow w-full ">
                        IN THE FUTURE
                      </span>
                      <div className="w-full flex flex-col gap-5 justify-center items-center bg-black bg-opacity-25 p-5 rounded-xl shadow">
                        <b className="text-center ">
                          Available for new opportunities after June 2024.
                        </b>
                        <p className=" rounded-xl gap-5  ">
                          If you're looking for a passionate, detail-oriented
                          web developer eager to further develop skills, learn,
                          and contribute to a dynamic team, I would be thrilled
                          to discuss potential opportunities.{" "}
                        </p>{" "}
                      </div>

                      <p className="gap-5  w-full text-center  bg-white bg-opacity-5 p-2 border-t border-gray-600 rounded-xl shadow ">
                        Let's connect,{" "}
                        <a
                          className=" font-bold w-fit hover:shadow-xl transition-all"
                          href="#contact"
                        >
                          message me.
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* My skills */}
          <div className=" transition-all duration-200  bg-slate-950 p-10 lg:p-20 relative">
            <div className="h-full flex flex-col w-full rounded-2xl max-w-screen-2xl mx-auto ">
              <div className="  justify-center items-center gap-2 flex absolute bg-white border  rounded-xl shadow-2xl p-2 w-1/2 lg:w-1/3 px-10 py-5 -top-11 mx-auto text-center  right-0 left-0">
                <h2 className=" text-2xl lg:text-4xl h-full flex justify-center font-bold text-[#0F172A] tracking-widest ">
                  SKILLSET
                </h2>
              </div>

              <div className="w-full flex flex-col gap-16 lg:gap-28 py-20 text-base lg:text-lg">
                <div className=" flex flex-1 flex-col justify-center gap-2  rounded-3xl   ">
                  <ul className="grid lg:grid-cols-3 grid-flow-row w-full h-full justify-between rounded-xl gap-5 lg:gap-10 ">
                    <li className="flex-1 flex relative flex-col h-full gap-5 justify-between items-center rounded-xl p-5 lg:p-10 shadow bg-white">
                      <h3 className="flex gap-2 h-1/3 items-center text-lg lg:text-xl text-center font-extrabold leading-none tracking-tight">
                        <img
                          src={Frontend}
                          className="w-10 lg:14 h-auto scale-90"
                        />{" "}
                        Front-end
                      </h3>
                      <hr className="w-full"></hr>
                      <p className="w-full h-2/3">
                        Proficient in building visually engaging and
                        user-friendly interfaces with HTML5, CSS3, JavaScript,
                        TypeScript, and frameworks like React.js, Angular and
                        Tailwind CSS.
                      </p>
                    </li>
                    <li className="flex-1 flex relative flex-col h-full gap-5 justify-between items-center rounded-xl p-5 lg:p-10 shadow bg-white">
                      <h3 className="flex gap-2 h-1/3 items-center text-lg lg:text-xl text-center font-extrabold leading-none tracking-tight">
                        <img
                          src={Backend}
                          className="w-10 lg:14 h-auto scale-90"
                        />
                        Back-end
                      </h3>
                      <hr className="w-full"></hr>
                      <p className="w-full h-2/3">
                        Capable of building robust backends to support web
                        applications and manage databases efficiently, using
                        Node.js and SQL, or cloud services such as Firebase.
                      </p>
                    </li>
                    <li className="flex-1 flex relative flex-col h-full gap-5 justify-between items-center rounded-xl p-5 lg:p-10 shadow bg-white">
                      <h3 className="flex gap-2 h-1/3 items-center text-lg lg:text-xl text-center font-extrabold leading-none tracking-tight">
                        <img
                          src={Responsive}
                          className="w-10 lg:14 h-auto scale-90"
                        />
                        Responsive Design
                      </h3>
                      <hr className="w-full"></hr>
                      <p className="w-full h-2/3">
                        Focused on creating websites that seamlessly adapt
                        across various screen sizes and devices, delivering an
                        optimal user experience for all visitors.
                      </p>
                    </li>
                    <li className="flex-1 flex relative flex-col h-full gap-5 justify-between items-center rounded-xl p-5 lg:p-10 shadow bg-white">
                      <h3 className="flex gap-2 h-1/3 items-center text-lg lg:text-xl text-center font-extrabold leading-none tracking-tight">
                        <img src={Ux} className="w-10 lg:14 h-auto scale-90" />
                        User Experience
                      </h3>
                      <hr className="w-full"></hr>
                      <p className="w-full h-2/3">
                        Dedicated to designing websites and applications with an
                        emphasis on user-centricity and ease of navigation.
                      </p>
                    </li>
                    <li className="flex-1 flex relative flex-col h-full gap-5 justify-between items-center rounded-xl p-5 lg:p-10 shadow bg-white">
                      <h3 className="flex gap-2 h-1/3 items-center text-lg lg:text-xl text-center font-extrabold leading-none tracking-tight">
                        <img
                          src={Coding}
                          className="w-10 lg:14 h-auto scale-90"
                        />
                        Coding
                      </h3>
                      <hr className="w-full"></hr>
                      <p className="w-full h-2/3">
                        Solid expertise in programming languages including
                        JavaScript, TypeScript, PHP, Java, and C#. Adept in
                        ASP.NET for dynamic web solutions.
                      </p>
                    </li>
                    <li className="flex-1 flex relative flex-col h-full gap-5 justify-between items-center rounded-xl p-5 lg:p-10 shadow bg-white">
                      <h3 className="flex gap-2 h-1/3 items-center text-lg lg:text-xl text-center font-extrabold leading-none tracking-tight">
                        <img
                          src={Problem}
                          className="w-10 lg:14 h-auto scale-90"
                        />
                        Problem-solving
                      </h3>
                      <hr className="w-full"></hr>
                      <p className="w-full h-2/3">
                        Analytical approach to problem resolution. Proven
                        ability to troubleshoot and find creative solutions to
                        technical challenges.
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="flex w-full flex-col lg:flex-row justify-between gap-16 lg:gap-20 text-slate-200 ">
                  <div className="flex-1 h-full flex flex-col justify-center gap-5">
                    <div className=" w-full  flex flex-col justify-center items-center ">
                      <h2 className="font-bold  rounded-xl text-2xl lg:text-3xl">
                        Primary skills
                      </h2>
                    </div>
                    <div className=" lg:p-5 flex flex-col gap-10 h-full">
                      {skillsBar.map((skill) => {
                        if (skill.type === "main") {
                          return (
                            <div
                              key={`${skill.name}-main`}
                              className="flex  items-center gap-5 w-full"
                            >
                              <div className=" flex items-center  gap-2 font-medium dark:text-black whitespace-nowrap">
                                <img
                                  src={skill.logo}
                                  alt="logo skill"
                                  className="w-8"
                                />
                                <span className="hidden lg:flex w-28">
                                  {skill.name}
                                </span>
                              </div>

                              <div className="flex-1 bg-white shadow-md rounded-full h-6 dark:bg-gray-700">
                                <div
                                  className=" h-6 rounded-full shadow-md"
                                  style={{
                                    width: `${skill.pourcentage}%`,
                                    backgroundColor: skill.color,
                                  }}
                                ></div>
                              </div>
                              <span className="font-medium dark:text-black">
                                {skill.pourcentage} %
                              </span>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                  <div className="flex flex-col rounded-xl gap-5 ">
                    <div className=" w-full  flex flex-col justify-center items-center ">
                      <h2 className="font-bold  rounded-xl text-xl lg:text-2xl ">
                        Secondary skills
                      </h2>
                    </div>
                    <ul className=" grid grid-cols-2 grid-flow-row h-full text-center p-2 gap-5 lg:gap-2 items-center">
                      {skillsBar.map((skill) => {
                        if (skill.type === "secondary") {
                          return (
                            <li
                              key={`${skill.name}-secondary`}
                              className="rounded-xl  text-white w-full p-2 h-fit flex items-center justify-center shadow-xl"
                              style={{
                                backgroundColor: skill.color,
                              }}
                            >
                              {skill.name}
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
