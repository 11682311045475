import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/NavBar";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import { FooterBrand } from "flowbite-react/lib/esm/components/Footer/FooterBrand";

function App() {
  return (
    <div>
      <div className="from-[#1F25AF] to-slate-900 from-90%  bg-gradient-to-t overflow-hidden ">
        <Router>
          <div className="relative font-custom h-full w-full flex flex-col items-start justify-start">
            <Navbar />
            <div className="flex-1 transition-all h-full  duration-1000 ease-in-out flex flex-col justify-center w-full relative">
              <div className=" overflow-hidden  bg-clip-padding backdrop-filter backdrop-blur-sm  ">
                <About id="about" />
                <Projects />
                <Contact />
                <FooterBrand />
              </div>
            </div>
          </div>
        </Router>
      </div>
    </div>
  );
}

export default App;
